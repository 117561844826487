import React from 'react'
import Grid from '@material-ui/core/Grid';
import Facebook from '../../../assets/facebook_category.png';
import Twitter from '../../../assets/twitter_category.png';
import LinkedIn from '../../../assets/linkedin_category.png';
import Email from '../../../assets/email_category.png';
import './ResearchContentcategory.scss'

export default function ResearchContentCategory() {
  return (
   <>

   <Grid container spacing={1}>
   {/* Social Media */}
   <Grid container spacing={1} className="social-media">
                {/* <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid> */}
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item container xl={6} lg={6} md={12} sm={12} xs={12}>
                        <div className="category-container">
                            <div className='category-para'>
                                <span className="cat-main-title">CATEGORY: <span className="cat-main-text">Policy and Program Guidance</span></span>
                                
                            </div>
                            <div className='category-para'>
                                <span className="cat-main-title">TAGS: <span className="cat-main-text">COLA, Compensation, Cost of Living
                                    Allowance, Inflation</span></span>
                                
                            </div>
                        </div>
                    </Grid>
                    <Grid item container xl={6} lg={6} md={12} sm={12} xs={12} className="social-container">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="icon-text">
                            <a href="https://www.linkedin.com/sharing/share-offsite/?url=" target="_blank">
                                <img src={LinkedIn} alt="LinkedIn" className='social-imgs' />
                            </a>
                            <a href="https://twitter.com/intent/tweet?&url=" target="_blank">
                                <img src={Twitter} alt="Twitter" className='social-imgs' />
                            </a>
                            <a href="https://www.facebook.com/sharer/sharer.php?u=" target="_blank">
                                <img src={Facebook} alt="Facebook" className='social-imgs' />
                            </a>
                            <a href="mailto:?subject=" target="_blank">
                                <img src={Email} alt="Email" className='social-imgs' />
                            </a>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="word-text">
                            <p className="word-caption">
                                Word count: 1878
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid> */}
    </Grid>
    </Grid>

   </>
  )
}


