import React from 'react'
import Layout from '../../staticcomponents/layout'
import ResearchContentHeader from './researchcontentheader'
import ResearchContentCategory from './researchcontentcategory'
import './researchcontent.scss'
import Container from '@material-ui/core/Container';



export default function ResearchContent() {
    return (
        <>
            <Layout>
                <div className='ResearchContent-main'>
                    <div className='bg-donuts-research-content'></div>
                    <Container maxWidth="xl" className='podcastcontent-container-div'>
                        <ResearchContentHeader />
                        <ResearchContentCategory />
                    </Container>
                    
                </div>
            </Layout>
        </>
    )
}