import React from 'react'
import './ResearchContentHeader.scss'
import { Grid, Button } from "@mui/material";
import rsrch_cnt_hero_image from '../../../assets/researchContentBg.png';
import supply_chain from '../../../assets/globalSupplyChain.png';

export default function ResearchContentHeader() {
  return (
    <>
      <Grid container className="research-content-header-cls">
        {/* **********************    Text-with-Image-row    ********************** */}
        <Grid container className='research-header-container'>
        {/* <Grid item xl={1} lg={1} md={2} sm={0} xs={0}></Grid> */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="research-content-text-img">
          <Grid item xl={5} lg={5} md={12} sm={12} xs={12} className="research-content-header-txt">
            <div className="research-content-header-txt-div">
              <p className="research-content-header-date">
                RESEARCH / 22 JUN 2022
              </p>
              <h1 className="research-content-header-title">
                diversity, equity & inclusion and global mobility pulse survey
              </h1>
            </div>
          </Grid>

          <Grid item xl={7} lg={7} md={12} sm={12} xs={12} className="research-content-header-heroimg">
            <img src={rsrch_cnt_hero_image} alt="Research Content Hero" />
          </Grid>

        </Grid>
        </Grid>
        {/* **********************   Text-row-2    ********************** */}
        <Grid className='research-content-padding'>
        {/* <Grid item xl={1} lg={1} md={2} sm={0} xs={0}></Grid> */}
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={5} lg={5} md={12} sm={12} xs={12} className='supply-chain-img'>
            <div className="img-thumb-div"><img className="img-thumb" src={supply_chain} alt="Supply Chain" />
              <div className="aftertxt"><Button variant="contained" className='dwn-btn-white'>download</Button></div>
            </div>
          </Grid>
          <Grid item container xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="research-content-rich-txt">
              <p>
                If there’s one thing the global pandemic has taught us, it’s that flexibility is key to success. A core/flex policy
                approach is becoming the number one trend in relocation for a reason: it provides ultimate flexibility to companies
                and employees.
              </p>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="research-content-rich-txt">
              <p>
                Core/flex allows companies to create a unique program designed around “core” benefits, which are selected based upon
                a company’s culture and business goals while engaging employees to choose the “flexible” options that work best for
                them and their families. To learn more about the growing core/flex trend within the mobility sphere, Cartus recently
                conducted a survey about core/flex implementation within organizations and their paths moving forward.
              </p>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="research-content-rich-txt">
              <p>
                <span className='research-bold-txt'>Regional Pulse Survey:</span> EMEA and APAC To provide a more regional perspective, we conducted two follow-up surveys
                in September 2021: the first among organizations attending an industry event in London, garnering more than 20
                responses; the second in China at a mobility event in Shanghai, garnering nearly 50 responses. Download our Regional
                Pulse Survey Report: EMEA and APAC below.
              </p>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="research-content-rich-txt">
              <Button variant="contained" className='research-dwn-btn'>download</Button>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
      </Grid>
    </>
  )
}


